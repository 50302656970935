
import { defineComponent } from "vue";
import CatalogueGrid from "@/components/controls/catalogueGrid/CatalogueGrid.vue";
import { TableDesignConfig } from "@/components/controls/catalogueGrid/tableDesignConfig";
import { TableEditingConfig } from "@/components/controls/catalogueGrid/tableEditingConfig";
import { TableSelectionConfig } from "@/components/controls/catalogueGrid/tableSelectionConfig";
import { TextColumnConfig } from "@/components/controls/catalogueGrid/columns/textColumnConfig";
import { IntegerColumnConfig } from "@/components/controls/catalogueGrid/columns/integerColumnConfig";
import { DecimalColumnConfig } from "@/components/controls/catalogueGrid/columns/decimalColumnConfig";
import store from "@/store";
import api from "@/services/api";
import { ability } from "@/abilities/ability";

export default defineComponent({
  components: {
    CatalogueGrid,
  },
  props: {
    parentModel: { required: true },
    parentEditingConfig: { required: false, type: TableEditingConfig },
    selectMode: { required: false, type: Boolean, default: false },
  },
  computed: {},
  watch: {},
  methods: {
    setColums() {
      if (ability.can("view", "Modules.Order")) {
        this.columns.push(
          new TextColumnConfig({
            dataField: "orderDescription",
            caption: "Заказ",
            readOnly: true,
            cssClass: "wrappedColumn",
            alignment: "left",
          })
        );
      }
    },
    onContentReady(e: any) {
      this.$emit("content-ready", e);
    },
    onSelectionChanged(e: any) {
      this.$emit("selection-changed", e);
    },
  },
  data() {
    return {
      highlightedSchemes: null,
      columns: [
        new TextColumnConfig({
          dataField: "number",
          caption: "№",
          readOnly: true,
          alignment: "left",
        }),
        new TextColumnConfig({
          dataField: "planTransportResultDescription",
          caption: "Транспорт",
          readOnly: true,
          cssClass: "wrappedColumn",
          alignment: "left",
        }),
        new IntegerColumnConfig({
          dataField: "count",
          caption: "Кол-во (шт)",
          min: 0,
          readOnly: true,
          alignment: "left",
        }),
        new DecimalColumnConfig({
          dataField: "weight",
          caption: "Вес (кг)",
          readOnly: true,
          decimalPoints: 2,
          alignment: "left",
        }),
      ],
      selectionConfig: new TableSelectionConfig({
        mode: "single",
        hoverStateEnabled: true,
      }),
      designConfig: new TableDesignConfig({
        popUpColumnsCount: 1,
        popUpWidth: "850px",
        horizontalScroll: false,
      }),
      editingConfig: new TableEditingConfig({
        allowUpdating: false,
        allowAdding: false,
        allowDeleting: false,
        allowDeleteAll: false,
        confirmDelete: true,
        allowDataFill: false,
        allowExport: false,
        allowClearFilter: false,
        allowDeleteMultipleRows: false,
        mode: "row",
      }),
      pageSizes: [10, 20, 50, 100],
    };
  },
  created() {
    if (this.selectMode) {
      this.editingConfig = this.selectModeEditingConfig;
    }

    this.setColums();
  },
});
