import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CatalogueGrid = _resolveComponent("CatalogueGrid")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_CatalogueGrid, {
      parentModel: _ctx.parentModel,
      controller: 'PlanTransportGroupResultLoadingScheme',
      columns: _ctx.columns,
      editingConfig: _ctx.editingConfig,
      selectionConfig: _ctx.selectionConfig,
      designConfig: _ctx.designConfig,
      showPageSizeSelector: !_ctx.selectMode,
      pageSize: 10,
      pageSizes: _ctx.pageSizes,
      filterRowVisible: false,
      groupPanelVisible: false,
      headerFilterVisible: false,
      filterPanelVisible: false,
      onContentReady: _ctx.onContentReady,
      onSelectionChanged: _ctx.onSelectionChanged,
      selectMode: _ctx.selectMode,
      ref: "grid"
    }, null, 8, ["parentModel", "columns", "editingConfig", "selectionConfig", "designConfig", "showPageSizeSelector", "pageSizes", "onContentReady", "onSelectionChanged", "selectMode"])
  ]))
}